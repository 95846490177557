<template>
  <h1>About Project Para</h1>
  <div class="card">

    <p>A paraeducator is an individual employed to perform instructional and related non-instructional duties in school
      settings under the direction of a certificated teacher or administrator. Other terms used for the position of
      paraeducator include paraprofessional, teaching assistant, and instructional aide. Early Childhood Paraeducators
      are used at age levels (birth to 5) and work with students either indirectly, on a one-to-one basis, or in small
      and large groups.</p>

    <p>The Paraeducator Self Study Program is a web-based method for school districts to provide introductory training
      for their paraeducators. It contains several units pertaining to paraeducators' roles and responsibilities, and
      core knowledge and skills in reinforcing instruction. It is intended to be supplemental to the training provided
      by school districts, and assists schools in meeting the paraeducator training requirements of Title-I and 3 of 12
      credits for Early Childhood.</p>
  </div>
  <div style="align-items: start; justify-content: start; display: inline-block" id="about">
    <div class="card">
      <h3 class="progname" style="color: black;">Common Questions</h3>
      <p>
        Project PARA is a school based instructional resource that is designed to
        give schools optimal flexibility in how they decide to use the program. It
        contains fourteen units and three assessments that cover a variety of
        topics to aid paraeducators with the development of skills needed to work
        effectively in a classroom setting.
      </p>

      <p><strong>How is Project PARA Organized?</strong></p>

      <p>
        Project PARA has an organizational structure that flows from the school
        administrator downward. When a school is registered, it is given a login
        and from this login, it can add records for as many instructors and
        participants as it needs. The school administrator can either add
        instructors and/or play a dual role as both the school administrator and
        an instructor. It is from the instructor resources that schools add
        records for participants.
      </p>

      <div class="levels"></div>

      <p><strong>What is a participating school expected to do?</strong></p>

      <p>
        Schools that participate with Project PARA must agree to provide an
        instructor or mentor to oversee the participants that they register. There
        are online resources for reviewing and scoring work submitted by their
        participants. Each unit has a pretest, one or more activities and a
        posttest that must be evaluated by the instructor. The pretest and all but
        three of the posttests are short answer and scored automatically, however
        the activities and three of the posttests have essay questions that need
        to be read and evaluated. The instructor must evaluate each unit as a
        whole on a 'pass' or 'fail' basis.
      </p>

      <p>
        <strong>How much of the program is a paraeducator required to do?</strong>
      </p>

      <p>
        Each participating school can decide how much of the program is useful to
        their situation and may assign any or all of the units according to their
        needs.
      </p>

      <p>
        <strong
        >How much time does it take a paraeducator to do the units?</strong
        >
      </p>

      <p>
        The data that we have is based on the unit evaluations. This information
        is voluntary and self-reporting, but the average time reported is
        approximately 2 hours per unit.
      </p>

      <p>
        <strong
        >Is there any kind of certificate available upon completion?</strong
        >
      </p>

      <p>
        The program does not offer a completion certificate for the entire
        program. This program is designed to be a supplementary tool to help
        schools provide training. There are, however three certificates of
        completion offered for portions of the units. Two of these are significant
        only to participants from Nebraska, as they provide a completion
        certificate for the requirements for NCLB and for Early Childhood
        requirements as approved by the Nebraska Department of Education. The
        third certificate of completion is offered by Project PARA to participants
        who have completed the set of Special Education units which are designed
        as the core units to the project.
      </p>

      <p>
        When a participant has completed the requirements for each of the
        certificates, and this has been verified online by their instructor, a
        printable certificate will download from the Instructor Resources. Please
        note: the certificates of completion are offered as a commendation for
        work completed for the project but do not have any kind of affiliation
        with the University of Nebraska.
      </p>

      <p>
        <strong>How much does it cost to register and use this program?</strong>
      </p>

      <p>
        This program has been generously supported by the Nebraska Department of
        Education and is currently offered without charge. It is possible,
        however, that in the future, the project may have to institute a fee
        system for its use. Project PARA has been offering its online program
        since 1995 and will continue to do so free of charge for as long as
        possible.
      </p>
    </div>
  </div>
</template>

<style scoped>
.card {
  display: flex;
  /*max-width: 600px;*/
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  text-align: justify;
  border: 1px solid black;
  border-radius: 0;
  padding: 1%;
}
</style>